import React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { useInView } from "react-intersection-observer"
import getCountry from "@/utils/getCountry.js"
import { useIntl } from "react-intl"
import { ProBox, VideoBox, JPStores } from "@/new_components/Home/index"
import { buy_link } from "@/scripts/constant.js"
import style from "./index.module.less"
import { proBg_ada_pc } from "@/images/ada/index"
import { home_beam_pc, home_beam_mobile } from "@/images/ces/index"
import { home_air_pc, home_air_mobile } from "@/images/air"
import { proBg_air2_pc_right } from "@/images/air2/index"
import {
  home_air2_mobile,
  home_ada,
  home_beampro_mobile,
  home_beampro,
  home_video,
  home_video2,
  home_air2ultra,
  home_air2ultra_mobile,
  home_air2pro,
  home_air2pro_mobile,
  air_pc,
  air_mobile,
  beam_pc,
  beam_mobile,
  adapter_pc,
  adapter_mobile,
  home_hub,
  home_hub_mobile,
} from "@/images/home/index"
import Air2Svg from "@/images/SVG/air2.svg"
import HomeAir from "@/images/SVG/HomeAir.svg"
import EvaSvg from "@/images/SVG/home_beam_icon.svg"
import AdaSvg from "@/images/SVG/home_adapter_icon.svg"

import { BuyStickers, HomePro, FrameAnimation } from "@/components/Home/index"

const Index = () => {
  const region = getCountry()
  const intl = useIntl()

  const beampro_intro = {
    theme: "white",
    pro_key: "beampro",
    background: home_beampro,
    background_mobile: home_beampro_mobile,
    pro_name: "XREAL BEAM PRO",
    text_id: "home.beampro.slogan",
    learn_link: "/beampro",
    buy_link: buy_link[region]["beampro"].shop,
    buy_link_out: true,
  }
  const air2_ultra_intro = {
    theme: "white",
    pro_key: "air2ultra",
    background: home_air2ultra,
    background_mobile: home_air2ultra_mobile,
    pro_name: "AIR 2 ULTRA",
    text_id: "home.air2ultrades",
    learn_link: "/air2ultra",
    buy_link: buy_link[region]["air2ultra"].shop,
    buy_link_out: true,
  }
  const air2_intro = {
    theme: "white",
    pro_key: "air2",
    background: home_air2pro,
    background_mobile: home_air2pro_mobile,
    pro_name: "AIR 2 / PRO",
    text_id: "home.air2des",
    learn_link: "/air2",
    buy_link: "/air2#air2spec",
  }
  const air_intro = {
    theme: "white",
    pro_key: "air",
    background: air_pc,
    background_mobile: air_mobile,
    pro_name: "AIR",
    text_id: "home.airdes",
    learn_link: "/air",
    buy_link: "/air#air2spec",
  }
  const beam_intro = {
    theme: "white",
    pro_key: "beam",
    background: beam_pc,
    background_mobile: beam_mobile,
    pro_name: "BEAM",
    text_id: "home.beamdes",
    learn_link: "/beam",
    buy_link: "/beam#pro-buy",
  }
  const ada_intro = {
    theme: "black",
    pro_key: "adapter",
    background: adapter_pc,
    background_mobile: adapter_mobile,
    pro_name: "ADAPTER",
    text_id: "home.adades",
    learn_link: "/adapter",
    buy_link: "/adapter#pro-buy",
  }

  const hub_intro = {
    theme: "black",
    pro_key: "hub",
    background: home_hub,
    background_mobile: home_hub_mobile,
    pro_name: "HUB",
    text_id: "home.hub.slogan",
    learn_link: "",
    buy_link: buy_link[region]["hub"].shop,
    buy_link_out: true,
  }

  //th的首页⬇️
  const [beamAdaRef, beamAdaInView] = useInView({ threshold: 0 })
  const [air2Ref, air2InView] = useInView({ threshold: 0 })
  const th_air2_intro = {
    direction: "left",
    theme: "white",
    pro_name: "air2",
    background: proBg_air2_pc_right,
    background_mobile: home_air2_mobile,
    pro_logo: <Air2Svg />,
    text_id: "home.air2des",
    learn_link: "/air2",
    buy_link: "",
  }

  const th_beam_intro = {
    direction: "right",
    theme: "black",
    pro_name: "beam",
    background: home_beam_pc,
    background_mobile: home_beam_mobile,
    pro_logo: <EvaSvg />,
    text_id: "home.beamdes",
    learn_link: "/beam",
    buy_link: "",
  }
  const th_ada_intro = {
    direction: "left",
    theme: "black",
    pro_name: "adapter",
    background: proBg_ada_pc,
    background_mobile: home_ada,
    pro_logo: <AdaSvg />,
    text_id: "home.adades",
    learn_link: "/adapter",
    buy_link: "",
  }
  const th_air_intro = {
    direction: "left",
    theme: "white",
    pro_name: "air",
    background: home_air_pc,
    background_mobile: home_air_mobile,
    pro_logo: <HomeAir />,
    text_id: "home.airdes",
    learn_link: "/air",
    buy_link: "",
    sale_shop: !!buy_link[region]["air"].sale_shop_price ? true : false,
  }

  const [block1Ref, block1InView] = useInView({ threshold: 0 })
  const [block2Ref, block2InView] = useInView({ threshold: 0 })
  const [block3Ref, block3InView] = useInView({ threshold: 0.5 })
  const [block4Ref, block4InView] = useInView({ threshold: 0 })
  const [block5Ref, block5InView] = useInView({ threshold: 0 })
  const [firstScreenRef, firstScreenInView] = useInView({ threshold: 0 })

  return (
    <Layout
      menuBlack={
        region == "th"
          ? !air2InView && beamAdaInView
            ? true
            : false
          : block1InView ||
            (block2InView && !block3InView) ||
            (!!block5Ref && block5InView && !block4InView)
          ? true
          : false
      }
    >
      <SEO page="Home" />
      {region !== "th" && (
        <>
          <div ref={block1Ref}>
            <div ref={firstScreenRef}>
              {" "}
              <ProBox intro={beampro_intro} />
            </div>
            <ProBox intro={air2_ultra_intro} />
          </div>

          <ProBox intro={air2_intro} />
          <div className={style.proBoxTwo}>
            <ProBox intro={air_intro} />
            <ProBox intro={beam_intro} />
          </div>
          <div className={style.proBoxSticky} ref={block3Ref}>
            {/* 视频 */}
            <div className={style.proBoxVideo}>
              <VideoBox video_link={home_video} go_link="/beampro" />
            </div>

            <div className={style.proBoxTwo} ref={block2Ref}>
              <ProBox intro={ada_intro} />
              <ProBox intro={hub_intro} />
            </div>
          </div>
          {/* 视频 */}
          <div className={style.proBoxVideo} ref={block4Ref}>
            <VideoBox video_link={home_video2} />
          </div>
          {region == "jp" && (
            <div ref={block5Ref}>
              <JPStores />
            </div>
          )}
        </>
      )}
      {region === "th" && (
        <>
          <FrameAnimation />
          <div ref={air2Ref}>
            <HomePro pro_intro={th_air2_intro} />
            <HomePro pro_intro={th_air_intro} />
          </div>
          <div ref={beamAdaRef}>
            <HomePro pro_intro={th_beam_intro} />
            <HomePro pro_intro={th_ada_intro} />
          </div>
          <BuyStickers />
        </>
      )}
    </Layout>
  )
}

export default Index
